<template>
  <div class="flex flex-col stats bg-transparent h-screen w-screen">
    <div class="content w-screen flex-col justify-center">
      <div class="flex gap-12 text-white p-4 font-semibold justify-center mt-5">
        <h3>Missions</h3>
      </div>
      <div class="bg-[#727886] h-[1px] mx-5"></div>

      <!-- Make the content scrollable within this div -->
      <div class="p-4 w-screen overflow-y-auto" style="max-height: calc(100vh - 150px);">
        <!-- Mission cards -->
        <MissionCard v-for="mission in missions" :key="mission.id" :mission="mission" />

        <!-- Referral claim card -->
        <div class="mt-5 border border-[#4f9f1a] bg-[#4f9f1a] bg-opacity-10 rounded-2xl p-3 flex items-center justify-between relative overflow-hidden" v-if="refClaim">
          <div class="flex gap-3 ms-1 items-center justify-start">
            <img src="../assets/share.svg" alt="icon" class="h-10 w-10 overflow-visible rounded-full text-white" style="object-fit: contain;">
            <div class="flex flex-col items-start">
              <h4 class="text-white font-semibold text-lg">Invite {{ refClaim.requiredRefCount }} friends!</h4>
              <div class="flex gap-1 items-center justify-center">
                <h3 class="text-white font-bold">+{{ refClaim.reward.toLocaleString() }}</h3>
                <img :src="store.state.appIcon" alt="appicon" class="h-4 w-4 rounded-full"/>
              </div>
            </div>
          </div>

          <button type="button" v-on:click="claimRef" :disabled="user.friends.length < refClaim.requiredRefCount" class="text-white font-semibold rounded-full px-5 py-2 mt-2 mb-2 text-center" :class="user.friends.length >= refClaim.requiredRefCount ? 'bg-[#4f9f1a]' : 'bg-gray-700'">
            <h1>Claim</h1>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import store from '@/store/index';
import MissionCard from '@/components/MissionCard.vue';
import UserService from '@/service/userService';

const missions = computed(() => {
  return store.state.missions;
});

const user = computed(() => {
  return store.state.user;
})

const refClaim = computed(() => {
  return store.state.refClaims.find(claim => user.value.refClaimLevel == null ? claim.level == 0 : claim.level == user.value.refClaimLevel);
});

const claimRef = async () => {
  if(refClaim.value.requiredRefCount > user.value.friends.length) return;

  const res = await UserService.claimRef(user.value.telegramId, refClaim.value.level);

  if(res.user) {
    store.commit('setUser', res.user);
  }
}
</script>

<style>
* {
  touch-action: manipulation;
}
</style>