let interval = null;
const store = require('@/store/index');

const startMining = async (minePerSecond) => {
    interval = setInterval(() => {
        const newBalance = store.default.state.user.balance + minePerSecond;

        store.default.commit('setBalance', newBalance);
    }, 1000);
};

const stopMining = () => {
    clearInterval(interval);
    interval = null;
};

export default { startMining, stopMining, interval };