import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {},
    countdown: '00:00:00',
    nextClaim: Date.now(),
    missions: [],
    nextDailyClaim: Date.now(),
    verifyingMission: [],
    linksDone: [],
    ranks: [],
    leaderboard: [],
    claimUpgrades: [],
    mineUpgrades: [],
    tapUpgrades: [],
    dailyRewards: [],
    energyUpgrades: [],
    refClaims: [],
    appIcon: '',
    appRewards: {},
    tapClaimReward: 0,
    luckyCardConfig: {},
    comboCards: {},
    tapLimit: 0,
  },
  getters: {
    user: state => state.user,
    countdown: state => state.countdown,
    nextClaim: state => state.nextClaim,
    missions: state => state.missions,
    nextDailyClaim: state => state.nextDailyClaim,
    verifyingMission: state => state.verifyingMission,
    linksDone: state => state.linksDone,
    ranks: state => state.ranks,
    leaderboard: state => state.leaderboard,
    claimUpgrades: state => state.claimUpgrades,
    mineUpgrades: state => state.mineUpgrades,
    tapUpgrades: state => state.tapUpgrades,
    energyUpgrades: state => state.energyUpgrades,
    dailyRewards: state => state.dailyRewards,
    appIcon: state => state.appIcon,
    refClaims: state => state.refClaims,
    appRewards: state => state.appRewards,
    tapClaimReward: state => state.tapClaimReward,
    luckyCardConfig: state => state.luckyCardConfig, // Added getter for luckyCardConfig
    comboCards: state => state.comboCards,
    tapLimit: state => state.tapLimit,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setMissions(state, missions) {
      state.missions = missions;
    },
    setStorage(state, storage) {
      state.user.storage = storage;
    },
    setBalance(state, balance) {
      state.user.balance = balance;
    },
    setCountdown(state, countdown) {
      state.countdown = countdown;
    },
    setNextClaim(state, nextClaim) {
      state.nextClaim = nextClaim;
    },
    setNextDailyClaim(state, nextDailyClaim) {
      state.nextDailyClaim = nextDailyClaim;
    },
    addVerifyingMission(state, mission) {
      state.verifyingMission.push(mission);
    },
    removeVerifyingMission(state, mission) {
      state.verifyingMission = state.verifyingMission.filter(m => m !== mission);
    },
    addLinkDone(state, link) {
      state.linksDone.push(link);
    },
    removeLinkDone(state, link) {
      state.linksDone = state.linksDone.filter(l => l !== link);
    },
    setRanks(state, ranks) {
      state.ranks = ranks;
    },
    setLeaderboard(state, leaderboard) {
      state.leaderboard = leaderboard;
    },
    setClaimUpgrades(state, claims) {
      state.claimUpgrades = claims;
    },
    setMineUpgrades(state, upgrades) {
      state.mineUpgrades = upgrades;
    },
    setEnergyUpgrades(state, upgrades) {
      state.energyUpgrades = upgrades;
    },
    setDailyRewards(state, rewards) {
      state.dailyRewards = rewards;
    },
    setAppIcon(state, appIcon) {
      state.appIcon = appIcon;
    },
    setRefClaims(state, refClaims) {
      state.refClaims = refClaims;
    },
    setAppRewards(state, appRewards) {
      state.appRewards = appRewards;
    },
    setTapUpgrades(state, tapUpgrades) {
      state.tapUpgrades = tapUpgrades;
    },
    setTapClaimReward(state, reward) {
      state.tapClaimReward = reward;
    },
    setLuckyCardConfig(state, config) {
      state.luckyCardConfig = config; 
    },
    setComboCards(state, cards) {
      state.comboCards = cards;
    },
    setTapLimit(state, limit) {
      state.tapLimit = limit;
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setMissions({ commit }, missions) {
      commit('setMissions', missions);
    },
    setStorage({ commit }, storage) {
      commit('setStorage', storage);
    },
    setCountdown({ commit }, countdown) {
      commit('setCountdown', countdown);
    },
    setNextClaim({ commit }, nextClaim) {
      commit('setNextClaim', nextClaim);
    },
    setBalance({ commit }, balance) {
      commit('setBalance', balance);
    },
    setNextDailyClaim({ commit }, nextDailyClaim) {
      commit('setNextDailyClaim', nextDailyClaim);
    },
    addVerifyingMission({ commit }, mission) {
      commit('addVerifyingMission', mission);
    },
    removeVerifyingMission({ commit }, mission) {
      commit('removeVerifyingMission', mission);
    },
    addLinkDone({ commit }, link) {
      commit('addLinkDone', link);
    },
    removeLinkDone({ commit }, link) {
      commit('removeLinkDone', link);
    },
    setRanks({ commit }, ranks) {
      commit('setRanks', ranks);
    },
    setLeaderboard({ commit }, leaderboard) {
      commit('setLeaderboard', leaderboard);
    },
    setMineUpgrades({ commit }, upgrades) {
      commit('setMineUpgrades', upgrades);
    },
    setDailyRewards({ commit }, rewards) {
      commit('setDailyRewards', rewards);
    },
    setAppIcon({ commit }, appIcon) {
      commit('setAppIcon', appIcon);
    },
    setRefClaims({ commit }, refClaims) {
      commit('setRefClaims', refClaims);
    },
    setAppRewards({ commit }, appRewards) {
      commit('setAppRewards', appRewards);
    },
    setTapUpgrades({ commit }, tapUpgrades) {
      commit('setTapUpgrades', tapUpgrades);
    },
    setTapClaimReward({ commit }, reward) {
      commit('setTapClaimReward', reward);
    },
    setLuckyCardConfig({ commit }, config) {
      commit('setLuckyCardConfig', config); // Action to commit luckyCardConfig mutation
    },
    setComboCards({ commit }, cards) {
      commit('setComboCards', cards);
    },
    setEnergyUpgrades({ commit }, upgrades) {
      commit('setEnergyUpgrades', upgrades);
    },
    setTapLimit({ commit }, limit) {
      commit('setTapLimit', limit);
    }
  },
  modules: {}
})
