const axios = require('axios');
const config = require('../config');

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: config.default.baseUrl,
});

const createUser = async (user) => {
    const response = await axiosInstance.post('/user', user);
    return response.data;
};

const getUser = async (userId) => {
    try {
        const response = await axiosInstance.get('/user', {
            params: {
                id: userId,
            }
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const startMining = async (userId) => {
    try {
        const response = await axiosInstance.post('/user/start', { userId });
        return response.data;
    } catch (error) {
        console.error(error);
    }
} 

const tapClaim = async (userId) => {
    try {
        const response = await axiosInstance.post(`/user/${userId}/tap-claim`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
} 

const claimMission = async (userId, missionId) => {
    try {
        const res = await axiosInstance.post(`/user/${userId}/mission`, { missionId });
        return res.data;
    } catch(e) {
        console.error(e);
    }
};

const claimDaily = async (userId) => {
    try {
        const response = await axiosInstance.post(`/user/${userId}/claim-daily`);
        
        return response.data;
    } catch (error) {
        return { error };
    }
};


const upgradeEnergy = async (userId) => {
    const response = await axiosInstance.post(`/user/${userId}/upgrade-energy`);

    return response.data;
}

const upgradeTap = async (userId) => {
    const response = await axiosInstance.post(`/user/${userId}/upgrade-tap`);

    return response.data;
}

const claimStorage = async (userId) => {
    try {
        const response = await axiosInstance.post(`/user/${userId}/claim`);

        return response.data;
    } catch(e) {
        return e;
    }
}

const claimCombo = async (userId, comboCode) => {
    try {
        const response = await axiosInstance.post(`/user/${userId}/claim-combo`, { comboCode });

        return response.data;
    } catch(e) {
        return e;
    }
}

const claimRef = async (userId, level) => {
    try {
        const response = await axiosInstance.post(`/user/${userId}/claim-ref`, { level });

        return response.data;
    } catch(e) {
        return e;
    }
}

const saveWallet = async (userId, wallet) => {
    try {
        const response = await axiosInstance.post(`/user/${userId}/wallet`, { wallet });

        return response.data;
    } catch(e) {
        return e;
    }
}

const claimLuckyCard = async (userId, cardId) => {
    try {
        const response = await axiosInstance.post(`/user/${userId}/lucky-card`, { cardId });

        return response.data;
    } catch(e) {
        return e;
    }
}

module.exports = { createUser, getUser, startMining, tapClaim, claimMission, claimDaily, upgradeEnergy, upgradeTap, claimStorage, claimCombo, claimRef, saveWallet, claimLuckyCard };