<template>
  <div class="fixed bottom-3 w-full">
    <div class="bg-slate-900 rounded-2xl mx-7">
      <div class="flex justify-around items-center text-secondary-gray text-base font-medium p-3">
        <router-link to="/stats" class="flex flex-col items-center justify-center">
          <img :src="getIconSrc('/stats', 'social')" alt="friends" class="h-9">
        </router-link>
        <router-link to="/upgrades" class="flex flex-col items-center justify-center">
          <img :src="getIconSrc('/upgrades', 'upgrade')" alt="upgrade" class="h-9">
        </router-link>
        <router-link to="/" class="flex items-center justify-center w-12 h-12 rounded-full mb-2">
            <img :src="getIconSrc('/', 'home')" alt="home" class="h-9">
        </router-link>
        <router-link to="/missions" class="flex flex-col items-center justify-center">
          <img :src="getIconSrc('/missions', 'missions')" alt="missions" class="h-9">
        </router-link>
        <router-link to="/rewards" class="flex flex-col items-center justify-center">
          <img :src="getIconSrc('/rewards', 'rewards')" alt="rewards" class="h-9">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',

  mounted() {
    this.listenBottomBar();
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    activeMissions() {
      return this.$store.state.missions.filter((mission) => !this.user.missionsDone.includes(mission.id)).length;
    },
  },

  methods: {
    listenBottomBar() {
      this.$router.afterEach(() => {
        this.$forceUpdate();
      });
    },
    isActive(path) {
      return this.$route.path === path;
    },
    getIconSrc(path, iconName) {
      const isActive = this.isActive(path);
      return require(`../assets/bottom/${iconName}${isActive ? '-active' : ''}.svg`);
    }
  }
}
</script>
